@tailwind base;
@tailwind components;
@tailwind utilities;


input[type="date"]::-webkit-calendar-picker-indicator {
background-color: #FFFFFF;
padding-left: 10px;
padding-right: 10px;
border-radius: 4px;
cursor: pointer;
}
::-webkit-scrollbar {
    width: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100vh;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 100vh;
    border: 3px solid #FFFFFF;
    background: #FFFFFF;
  }
  
  /* Handle on hover */
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

  /* Button CSS */

  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    flex-grow: 1;
  }
  
  .btn-play {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #ffffff;
    cursor: pointer;
  }
  
  .btn-stop {
    height: 20px;
    width: 10px;
    border-left: 10px solid rgb(255, 255, 255);
    border-right: 10px solid rgb(255, 255, 255);
    cursor: pointer;
  }

  /* Control panel css */
  .control-panel {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
  }
  
  .timer {
    font-size: 10px;
    font-weight: 200;
    color: rgb(196, 196, 196);
  }

  
  /* Slider css */
  .slider-container {
    --progress-bar-height: 8px;
  }
  
  .slider-container {
    position: relative;
    width: 100%;
  }
  
  /* Background */
  .slider-container::before {
    content: '';
    background-color: white;
    width: 99%;
    height: calc(var(--progress-bar-height) - 1px);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  /* Custom Progress Bar */
  .progress-bar-cover {
    background-color: rgb(218, 55, 145);
    width: 0%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }
  
  /*  Hide Original */
  .range {
    -webkit-appearance: none;
    background-color: rgba(240, 9, 9, 0.397);
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
  }



  .nav--background {
    background: rgb(0,229,255);
    background: linear-gradient(90deg, rgba(0,229,255,1) 0%, rgba(0,255,220,1) 55%);    
}

.img--size {
    width: 900px;
    height: 50vh;
}

/* .player--icons {
    position: relative;
    top: 22rem;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.leaderboard--background {
    background: url('./assets/leaderboard-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.key--features--section {
    background: url('./assets/key-features-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    /* overflow: hidden; */
}

.awards--section {
    background: url('https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fawards.png?alt=media&token=b0b62999-304c-4290-8fa0-f746cacefb1a');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.footer--background {
    background: url('./assets/footer-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
} 

/* artist page */

.artist--background {
    background: url('./assets/footer-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

/* about features background */

.about--background {
    background: url('./assets/about-key-features-bloow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    z-index: 1;
}

.contact--background{
    background: url('./assets/contactForm.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: auto;
}


.reach--background{
    background: url('./assets/contactInformation.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: auto;
    z-index: 1;
}

.subscription--background{
    background: url('./assets/subscriptionForm.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: auto;
}
  

  .owl-prev, .owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    display: block !important;
    border:0px solid black;
}
.owl-prev { left: -20px; }
.owl-next { right: -20px; }
.owl-prev i, .owl-next i {color: black;font-size: 20px;font-weight: bolder;}


.owl-item.active.center {
    transform: scale(1.1, 1.2);
    margin-bottom: 7vh;
}

.social-icon {
    max-width: 100px;
    height: 110px;
}

/* .card--wrapper {
    min-width: 300px;
} */
@media only screen and (max-width: 600px) {
  .awards--section {
    background: url('https://firebasestorage.googleapis.com/v0/b/bloow-f5237.appspot.com/o/files%2Fimages%2Fartists%2FArtboard%20w1.png?alt=media&token=3898b627-67a4-4b50-aef0-62f291e74e3b');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
}
.social-icon {
  max-width: 50px;
  height: 55px;
}
.card--wrapper {
  min-width: 150px;
}
.key--features--section {

  height: 70vh;
}
.owl-item .event{
  height: 100px;
}
.owl-item.active.center .event{
  height: 150px;
}
.footer--background {

  width: 100vw;
} 
}
